import { isArray, get, has, forOwn, set } from 'lodash';

import ApiErrorsFormatter from '../Utils/ApiErrorsFormatter';
import appsignal from './ErrorReportingService';

const logAppSignal = (response: any) => {
  try {
    appsignal.sendError(new Error('API Error'));
    appsignal.addBreadcrumb({
      category: 'API',
      action: 'API Error',
      metadata: { ...response },
    });
  } catch (e) {
    console.log(e);
  }
};

export const extractErrors = (response: any, intl?: any) => {
  if (has(response, 'data.errors[0].detail')) {
    return response.data.errors;
  }

  logAppSignal(response);

  // We don't have server error data, only ApiSauce's
  // https://github.com/infinitered/apisauce#problem-codes
  return intl ? intl.formatMessage({ id: `API_SAUCE.${response.problem}` }) : response.problem;
};

export const generateErrors = (detail: any) => [{ detail }];

// TODO: We should stop using this and use extractErrorsForNestedAttributes instead
export const generateErrorsForWeb = (data: any) => {
  if (data?.status) {
    // Api v1 new errors formatting late 2020
    return ApiErrorsFormatter(data.errors);
  }

  if (!data || (data && !data.errors)) {
    return data;
  }

  const formatedErrors: Record<string, any> = {};

  data.errors.forEach((obj: any) => {
    if (isArray(obj.detail)) {
      obj.detail.forEach((subOobj: any) => {
        formatedErrors[`${obj.attribute}.${subOobj.attribute}`] = subOobj.detail;
      });
    } else {
      formatedErrors[obj.attribute] = obj.detail;
    }
  });

  return formatedErrors;
};

export function errorsToReactQueryErrors(obj: any) {
  const newObj: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    // Replace and reformat the key
    let newKey = key.replace(/\[(\d+)\]/, '.$1');
    newKey = newKey.replace(/\[(\d+)\]/, '.$1');

    newObj[newKey] = obj[key];
  });
  return newObj;
}

export const extractErrorForToaster = (data: any, intl: any) =>
  get(extractErrors(data, intl), '[0].detail') || '';

// List of attributes that we need to transform
// rails sends "name" but we want them to be "name_attributes"
const transformableAttributes = [
  'answers',
  'form_questions',
  'form_submissions',
  'question_options',
  'secured_strategy_submissions',
  'post_record_questions',
  'post_record_submissions',
];

function transformKeys(obj: any) {
  const newObj: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    // If the key contains any of the transformable attributes
    let newKey = key;

    transformableAttributes.forEach((attribute) => {
      if (key.includes(attribute)) {
        // Replace the key with the new key
        // Example: form_questions[0] => form_questions_attributes.0
        newKey = newKey.replace(
          new RegExp(`${attribute}\\[(\\d+)\\]`),
          `${attribute}_attributes.$1`,
        );
      }
    });

    newObj[newKey] = obj[key];
  });

  return newObj;
}

export const extractErrorsForNestedAttributes = (response: any) => {
  const errors = transformKeys(response.data.errors[0]);
  const errorsObject = {};

  forOwn(errors, (value, key) => {
    const existingError = get(errorsObject, key) || {};
    set(errorsObject, key, { ...existingError, message: value[0] });
  });

  return errorsObject;
};
