import apisauce, { ApiResponse } from 'apisauce';
import qs from 'qs';

import { currentLocale } from '../I18n';
import { Post } from '../Utils/Types/Posts';
import { Voter } from '../Utils/Types/Voters';
import { VotersImport } from '../Utils/Types/VotersImport';
import { VotersDuplicate } from '../Utils/Types/VotersDuplicate';

const apiClient = apisauce.create({
  // baseURL: `https://${window.runtimeEnv.REACT_APP_HOST_WWW}/`,
  headers: {
    Accept: 'application/json',
    'Accept-Language': currentLocale,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  withCredentials: true,
  timeout: 30000,
});

interface ResponseType<T> {
  data: T;
}

export const setApiLang = (lang: any) => apiClient.setHeader('Accept-Language', lang);
export const setSpaceSlug = (spaceSlug: any) => {
  window.spaceSlug = spaceSlug;

  return spaceSlug;
};

//
// Authentication
//
const sign = (
  email: any,
  providerName: any,
  providerId = undefined,
  invitationCode: any,
  spaceId: any,
): Promise<ApiResponse<any>> =>
  apiClient.post('/api/sign', {
    data: {
      email,
      provider_name: providerName,
      provider_id: providerId,
      invitation_code: invitationCode,
      space_id: spaceId,
    },
  });
const signIn = (
  email: any,
  password: any,
  providerName: any,
  providerToken = undefined,
  itsmeJwt = undefined,
  spaceId: any,
): Promise<ApiResponse<any>> =>
  apiClient.post(
    '/api/sign/in',
    {
      data: {
        email,
        password,
        provider_name: providerName,
        access_token: providerToken,
        space_id: spaceId,
      },
    },
    itsmeJwt
      ? {
          // used when doing a test signin for itsme's manual linking
          headers: {
            Authorization: `Bearer ${itsmeJwt}`,
          },
        }
      : {},
  );

export const resetPassword = (spaceId: any, data: any): Promise<ApiResponse<any>> =>
  apiClient.post('/api/reset_password', { space_id: spaceId, data });

export const updatePassword = (
  userId: any,
  tokenId: any,
  password: any,
): Promise<ApiResponse<any>> =>
  apiClient.patch('/api/reset_password', {
    data: { user_id: userId, token_id: tokenId, password },
  });
export const logout = () => apiClient.get('/api/signout');

//
// Questions
//
export const getQuestionResults = (questionId: number | string) => () => {
  return apiClient.get(`/api/spaces/${window.spaceSlug}/questions/${questionId}/results`);
};
export const updateQuestion =
  (postId: number | string) =>
  ({ questionId, data }: { questionId: number | string; data: any }) =>
    apiClient.put(`/api/spaces/${window.spaceSlug}/posts/${postId}/questions/${questionId}`, {
      data,
    });

//
// Form Responses
//
export const createFormResponse = (postId: number | string) => (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/posts/${postId}/form_responses`, { data });
export const updateFormResponse =
  (postId: number | string, formResponseId: number | string) => (data: any) =>
    apiClient.put(
      `/api/spaces/${window.spaceSlug}/posts/${postId}/form_responses/${formResponseId}`,
      {
        data,
      },
    );

export const getFormResponse = (postId: number | string, formResponseId: number | string) => () =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/posts/${postId}/form_responses/${formResponseId}`);

export const deleteFormResponse =
  (postId: number | string, formResponseId: number | string) => () =>
    apiClient.delete(
      `/api/spaces/${window.spaceSlug}/dashboard/posts/${postId}/form_responses/${formResponseId}`,
    );

export const getFormResponses = (postId: number | string, queryParams: any) => () => {
  const { page = 0, limit = 10, ...rest } = queryParams || {};
  const params = qs.stringify(
    {
      page: {
        limit,
        offset: page,
      },
      ...rest,
    },
    { arrayFormat: 'brackets' },
  );

  return apiClient.get(
    `/api/spaces/${window.spaceSlug}/dashboard/posts/${postId}/form_responses?${params}`,
  );
};

//
// Voters
//
export const getVoters = (postId: number | string, queryParams: any) => () => {
  const { page = 0, limit = 10, ...rest } = queryParams || {};
  const params = qs.stringify(
    {
      page: {
        limit,
        offset: page,
      },
      ...rest,
    },
    { arrayFormat: 'brackets' },
  );

  return apiClient.get<ResponseType<Voter[]>>(
    `/api/spaces/${window.spaceSlug}/posts/${postId}/voters?${params}`,
  );
};

export const exportVoters = ({
  format = 'xlsx',
  postId,
  params,
}: {
  format?: string;
  postId: number | string;
  params: any;
}) => {
  return `/api/export/posts/${postId}/voters.${format}?${qs.stringify(params, {
    arrayFormat: 'brackets',
  })}`;
};

export const votersImportTemplate = (postId: number | string) => {
  return `/api/spaces/${window.spaceSlug}/posts/${postId}/voters_imports/template`;
};

export const deleteVoter = (postId: number | string, voterId: number) => () =>
  apiClient.delete(`/api/spaces/${window.spaceSlug}/posts/${postId}/voters/${voterId}`);

export const deleteManyVoters = (postId: number | string, voterIds: number[]) => () =>
  apiClient.delete(`/api/spaces/${window.spaceSlug}/posts/${postId}/voters/many`, {
    ids: voterIds,
  });

//
// Voters Import
//
export const getVotersImport = (postId: number | string) => () => {
  return apiClient.get<ResponseType<VotersImport>>(
    `/api/spaces/${window.spaceSlug}/posts/${postId}/voters_imports`,
  );
};

export const createVotersImport = (postId: number | string) => (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/posts/${postId}/voters_imports`, { data });

export const getVotersDuplicates = (postId: number | string, refresh: boolean) => () => {
  return apiClient.get<ResponseType<VotersDuplicate[]>>(
    `/api/spaces/${window.spaceSlug}/posts/${postId}/voters_duplicates${refresh ? '?refresh=true' : ''}`,
  );
};

export const updateVotersDuplicate =
  (postId: number | string, votersDuplicateId: number, decision: 'keep' | null) => () =>
    apiClient.put(
      `/api/spaces/${window.spaceSlug}/posts/${postId}/voters_duplicates/${votersDuplicateId}`,
      { data: { decision } },
    );

//
// Participants
//

export const exportParticipants = ({
  format = 'xlsx',
  postId,
  params,
}: {
  format?: string;
  postId: number | string;
  params: any;
}) => {
  return `/api/export/posts/${postId}/participants.${format}?${qs.stringify(params, {
    arrayFormat: 'brackets',
  })}`;
};

//
// Secured Strategy Submissions
//

export const getSecuredStrategySubmission =
  (postId: number | string, formRepsonseId: number | string) => () =>
    apiClient.get(
      `/api/spaces/${window.spaceSlug}/posts/${postId}/secured_strategy_submissions/${formRepsonseId}`,
    );

export const createSecuredStrategySubmission = (postId: number | string) => (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/posts/${postId}/secured_strategy_submissions`, {
    data,
  });

export const updateSecuredStrategySubmission =
  (postId: number | string, formRepsonseId: number | string) => (data: any) =>
    apiClient.put(
      `/api/spaces/${window.spaceSlug}/posts/${postId}/secured_strategy_submissions/${formRepsonseId}`,
      { data },
    );

//
// Post Permissions
//

export const getPostPermissions = (postId: number | string): Promise<ApiResponse<any>> => {
  return apiClient.get(`/api/spaces/${window.spaceSlug}/posts/${postId}/permissions`);
};

export const createPostPermission = (postId: number | string, userId: any) => () => {
  return apiClient.post(
    `/api/spaces/${window.spaceSlug}/posts/${postId}/permissions?user_id=${userId}`,
  );
};

export const destroyPostPermission = (postId: number | string, userId: any) => () => {
  return apiClient.delete(
    `/api/spaces/${window.spaceSlug}/posts/${postId}/permissions?user_id=${userId}`,
  );
};

//
// Posts
//
export const getPost = (postId: number | string) => () => {
  return apiClient.get<ResponseType<Post>>(`/api/spaces/${window.spaceSlug}/posts/${postId}`);
};

export const exportPostResults = ({
  format = 'xlsx',
  postId,
  params,
}: {
  format?: string;
  postId: number | string;
  params: any;
}) => {
  return `/api/export/posts/${postId}/results.${format}?${qs.stringify(params, {
    arrayFormat: 'brackets',
  })}`;
};

export const exportPostFormResponses = ({
  format = 'xlsx',
  postId,
  params,
}: {
  format?: string;
  postId: number | string;
  params: any;
}) => {
  return `/api/export/posts/${postId}/form_responses.${format}?${qs.stringify(params, {
    arrayFormat: 'brackets',
  })}`;
};

const getPosts = ({
  page,
  types = [],
  status,
  visibilities = [],
  sort = '',
  limit = 10,
  originalPostId,
  parentId,
  assigneeId,
  authorId,
  tags = [],
  iVotedFor,
  search = '',
  extraParams = {},
}: any) => {
  const { filter, payload, namespace, ...xtras } = extraParams;
  const params = qs.stringify(
    {
      page: {
        limit,
        offset: page,
      },
      sort,
      filter: {
        type: types,
        tag_id: tags,
        visibility: visibilities,
        original_post_id: originalPostId,
        parent_id: parentId,
        assignee_id: assigneeId,
        author_id: authorId,
        i_voted_for: iVotedFor,
        status,
        q: search,
        ...filter,
      },
      ...xtras,
    },
    {
      arrayFormat: 'brackets',
      encode: false,
    },
  );
  if (payload === 'map') {
    return apiClient.get(`/api/spaces/${window.spaceSlug}/posts/map?${params}`);
  }
  if (namespace === 'dashboard') {
    return apiClient.get(`/api/spaces/${window.spaceSlug}/dashboard/posts?${params}`);
  }

  return apiClient.get<ResponseType<Post[]>>(`/api/spaces/${window.spaceSlug}/posts?${params}`);
};

export const exportPosts = ({ format = 'xlsx', params }: { format?: string; params: any }) => {
  return `/api/export/posts.${format}?${qs.stringify(params, {
    arrayFormat: 'brackets',
  })}`;
};

export const getAddressCities = (postId: number | string) => () =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/posts/${postId}/address_cities`);

const getMessages = ({ page, sort = '', limit = 10, ...extraParams }: any) => {
  const params = qs.stringify(
    {
      page: {
        limit,
        offset: page,
      },
      sort,
      ...extraParams,
    },
    {
      arrayFormat: 'brackets',
      encode: false,
    },
  );
  return apiClient.get(`/api/me/messages?${params}`);
};

export const votePost = (postId: number | string, voteValue: any, isCancelingVote: any) => {
  const url = `/api/posts/${postId}/vote`;

  if (isCancelingVote) {
    return apiClient.delete(url, { space_slug: window.spaceSlug });
  }

  return apiClient.post(url, {
    space_slug: window.spaceSlug,
    data: { vote_value: voteValue },
  });
};

export const pinUnpinPost = (postId: number | string) => (shouldBePinned: boolean) => {
  if (shouldBePinned) {
    return apiClient.post(`/api/posts/${postId}/pin`, { space_slug: window.spaceSlug });
  }
  return apiClient.delete(`/api/posts/${postId}/pin`, { space_slug: window.spaceSlug });
};

export const publishUnpublishPost = (postId: number | string, isPublished: any) => {
  if (isPublished) {
    return apiClient.delete(`/api/posts/${postId}/publish`);
  }

  return apiClient.post(`/api/posts/${postId}/publish`);
};

export const destroyAttachment = (postId: number | string, id: any) =>
  apiClient.delete(`/api/posts/${postId}/attachments/${id}`, { space_slug: window.spaceSlug });

export const virusScanStatus = (blobKey: any) =>
  apiClient.get(`/api/virus_scan_statuses/${blobKey}`);

export const reportPostAsAbuse = (postId: number | string, data: any) =>
  apiClient.post(`/api/posts/${postId}/report`, {
    space_slug: window.spaceSlug,
    ...data,
  });
export const reportCommentAsAbuse = (postId: number | string, commentId: any, data: any) =>
  apiClient.post(`/api/posts/${postId}/comments/${commentId}/report`, {
    space_slug: window.spaceSlug,
    ...data,
  });

export const createPost = (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/posts`, { data });
export const updatePost = (postId: number | string) => (data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/posts/${postId}`, { data });

//
// Translations
//
const getCommentTranslation = (commentId: any, targetLang: any) =>
  apiClient.get(`/api/comments/${commentId}/translate?target_lang=${targetLang}`);
export const translate = ({ targetLang, data }: { targetLang: string; data: object }) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/translate`, {
    target_lang: targetLang,
    data_hash: data,
  });

//
// Post Secured Strategies
//
export const getPostSecuredStrategies = (postId: number | string) =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/posts/${postId}/secured_strategies`);

//
// Post Audits
//
export const getPostAudits = (postId: number | string) =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/posts/${postId}/audits`);

//
// PreventGo Identity check controller
//
export const checkIdentityViaPreventGo = (data: any) =>
  apiClient.post(`/api/prevent_go/identity_checks`, data, {
    headers: { 'Content-Type': 'multipart/form' },
  });

//
// Tags
//
export const getTags = () => apiClient.get(`/api/spaces/${window.spaceSlug}/tags`);

//
// Users Filters
//

export const getUsersFilters = () =>
  apiClient
    .get(`/api/spaces/${window.spaceSlug}/dashboard/users_filters`)
    .then((res: any) => res.data);

//
// Spaces
//
export const getSpace = (spaceSlug: any) => apiClient.get(`/api/spaces/${spaceSlug}`);
export const updateSpace = () => (data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}`, { data });
export const getSpaceByCustomDomain = (customDomain: any) =>
  apiClient.get(`/api/spaces/by_custom_domain?custom_domain=${customDomain}`);
const getMyEntities = () => apiClient.get('/api/me/spaces');
const getSpaces = (q: any) => apiClient.get(`/api/spaces?q=${q}`);

//
// Profile
//
export const getProfile = () => apiClient.get('/api/settings/profile');
const updateProfile = (data: any, spaceId: any) =>
  apiClient.patch('/api/settings/profile', { data, space_id: spaceId });

//
// Proof of identities
//
const updateProofOfIdentity = (data: any) =>
  apiClient.patch(`/api/spaces/${window.spaceSlug}/proof_of_identity`, {
    data,
  });

export const getProofOfIdentity = () =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/proof_of_identity`);

//
// password
//
const updateMyPassword = (currentPassword: any, newPassword: any) =>
  apiClient.put('/api/settings/password', {
    data: {
      current_password: currentPassword,
      password: newPassword,
    },
  });
const createPassword = (password: any) =>
  apiClient.post('/api/settings/password', { data: { password } });

// Verify email
export const verifyResetPin = () => apiClient.put(`/api/me/verify/reset`);
export const verifyPin = () => (data: any) => apiClient.put(`/api/me/verify`, data);

//
// Email subscription
//
export const unsubscribeFromEmailList = ({ uuid, data }: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/notification_settings/${uuid}`, { data });

//
// Professional Categories
//
export const getProfCategories = () => apiClient.get('/api/prof_categories');

//
// Comments
//
// TODO: merge with getDiscussionNotes
export const getCommentsForPost =
  (postId: number | string, { page, limit = 10, ...rest }: any) =>
  () => {
    const params = qs.stringify(
      {
        page: {
          limit,
          offset: page,
        },
        ...rest,
      },
      { arrayFormat: 'brackets' },
    );

    return apiClient.get<ResponseType<any[]>>(
      `/api/spaces/${window.spaceSlug}/posts/${postId}/comments/?${params}`,
    );
  };

export const getDiscussionNotes =
  (postId: number | string, { page, limit = 10, ...rest }: any) =>
  () => {
    const params = qs.stringify(
      {
        post_record_id: postId,
        page: {
          limit,
          offset: page,
        },
        ...rest,
      },
      { arrayFormat: 'brackets' },
    );

    return apiClient.get(`/api/spaces/${window.spaceSlug}/dashboard/comments?${params}`);
  };

export const getAuthentications = () => {
  return apiClient.get(`/api/settings/authentications`);
};

export const destroyAuthentication = (id: any) => {
  return apiClient.delete(`/api/settings/authentications/${id}`);
};

//
// Assignments
//
export const createAssignment = () => (data: any) => {
  return apiClient.post(`/api/spaces/${window.spaceSlug}/assignments?${qs.stringify(data)}`);
};
export const destroyAssignment = (id: any) => () => {
  return apiClient.delete(`/api/spaces/${window.spaceSlug}/assignments/${id}`);
};
export const createExportAssignments = (params: any) => {
  const query = qs.stringify(params, { arrayFormat: 'brackets' });
  return apiClient
    .post(`/api/spaces/${window.spaceSlug}/export/assignments?${query}`)
    .then((res: any) => res.data);
};

export const postDiscussionNote =
  (postId: number | string, { parentId, type }: any) =>
  (data: any) => {
    let finalData = data;

    if (parentId) {
      finalData = { ...data, note_id: parentId };
    }

    return apiClient.post(
      `/api/spaces/${window.spaceSlug}/dashboard/comments?post_record_id=${postId}&type=${type}`,
      {
        data: finalData,
      },
    );
  };

export const postComment = (postId: number | string) => (data: any) => {
  return apiClient.post(`/api/posts/${postId}/comments`, { data });
};

export const deleteComment = (postId: number | string, commentId: any) =>
  apiClient.delete(`/api/posts/${postId}/comments/${commentId}`);

export const deletePrivateComment = (commentId: any) =>
  apiClient.delete(`/api/spaces/${window.spaceSlug}/dashboard/comments/${commentId}`);

export const postCommentForComment =
  (postId: number | string, noteRecordId: any) => (data: any) => {
    return apiClient.post(`/api/posts/${postId}/comments`, {
      data: { ...data, note_id: noteRecordId },
    });
  };

export const postManyComments = ({
  filter,
  content,
  status,
  original_post_id: originalPostId,
}: any) => {
  return apiClient.post('/api/posts/comments/many', {
    filter,
    space_slug: window.spaceSlug,
    data: { content, status, original_post_id: originalPostId },
  });
};

export const updatePostDashboard = (postId: number | string) => (data: any) => {
  return apiClient.put(`/api/spaces/${window.spaceSlug}/dashboard/posts/${postId}`, { data });
};

const reportComment = (ideaId: any, commentId: any, reason: any) =>
  apiClient.post(`/api/posts/${ideaId}/comments/${commentId}/report`, {
    data: {
      reason,
    },
  });

//
// Status
//
export const updatePostStatus = (postId: number | string) => (data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/posts/${postId}/status`, { data });

const getPostStatus = (postId: number | string) =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/posts/${postId}/statuses`);

//
// Delete a Post
//
export const deletePost = (postId: number | string) => () =>
  apiClient.delete(`/api/spaces/${window.spaceSlug}/posts/${postId}`);

//
// Clone a Post
//
export const clonePost = (postId: number | string) => () =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/dashboard/posts/${postId}/clone`);

//
// Report Reasons
//
export const getReportReasons = () => () =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/report_reasons`);

export const postReportForPost = (data: any, postId: number | string) =>
  apiClient.post(`/api/posts/${postId}/report`, data);

//
// Authentication
//
export const login = (data: any) => apiClient.post('/users/sign_in', data);

export const signUp = (data: any) => apiClient.post('/api/users', data);

export const sso = (strategy: string, data: any) =>
  apiClient.post(`/users/auth/${strategy}/callback`, data, {
    // https://github.com/zquestz/omniauth-google-oauth2?tab=readme-ov-file#note-about-cors
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });

export const updateEntitySubscription = (userId: any, data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/entity_subscriptions`, {
    user_id: userId,
    ...data,
  });

export const createEntitySubscription = (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/entity_subscriptions`, data);

export const deleteEntitySubscription = (userId: any) =>
  apiClient.delete(`/api/spaces/${window.spaceSlug}/entity_subscriptions`, { user_id: userId });
export const destroyAccount = () => apiClient.delete(`/api/spaces/${window.spaceSlug}/users`);

//
// Stats
//
export const getStats = (
  postId: number | string,
  statsSlug: any,
  from: any,
  to: any,
  interval: any,
) => {
  const params = qs.stringify({ from, to, interval }, { arrayFormat: 'brackets' });
  return apiClient.get(`/api/posts/${postId}/stats/${statsSlug}?${params}`);
};

export const getDashboardStats = (
  postId: number | string,
  newsletterId: any,
  statsSlug: any,
  from: any,
  to: any,
  interval: any,
  dimensionId: any,
  scope: any,
) => {
  const params = qs.stringify(
    {
      post_record_id: postId,
      newsletter_id: newsletterId,
      from,
      to,
      interval,
      dimension_id: dimensionId,
      scope,
    },
    { arrayFormat: 'brackets' },
  );
  return apiClient.get(`/api/spaces/${window.spaceSlug}/dashboard/stats/${statsSlug}?${params}`);
};

//
// AI data
//
export const getAIData = (postId: number | string) =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai`);
export const generateAIForPost = (postId: number | string) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai`);

// accepts data: { name, description }
export const createCluster = (postId: number | string, data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai/create_cluster`, { data });
// accepts data: { name, description }
export const createSubCluster = (postId: number | string, clusterId: any, data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai/create_sub_cluster`, {
    data: { ...data, cluster_id: clusterId },
  });

// accepts data: { name, description }
export const updateCluster = (postId: number | string, clusterId: any, data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai/update_cluster`, {
    data: { ...data, cluster_id: clusterId },
  });
// accepts data: { name, description }
export const updateSubCluster = (
  postId: number | string,
  clusterId: any,
  subClusterId: any,
  data: any,
) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai/update_sub_cluster`, {
    data: { ...data, cluster_id: clusterId, sub_cluster_id: subClusterId },
  });

export const deleteCluster = (postId: number | string, clusterId: any) =>
  apiClient.delete(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai/destroy_cluster`, {
    data: { cluster_id: clusterId },
  });
export const deleteSubCluster = (postId: number | string, clusterId: any, subClusterId: any) =>
  apiClient.delete(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai/destroy_sub_cluster`, {
    data: { cluster_id: clusterId, sub_cluster_id: subClusterId },
  });

// accepts data: { idea_id, sub_cluster_id, idea_id }
export const moveIdea = (postId: number | string, data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/posts/${postId}/ai/move_idea`, { data });

//
// Attendances
//
export const getAttendances = (postId: number | string) => () =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/attendances?post_record_id=${postId}`);
export const createAttendance = (postId: number | string, attendanceStatus: any) => () =>
  apiClient.post(
    `/api/spaces/${window.spaceSlug}/attendances?post_record_id=${postId}&attendance_status=${attendanceStatus}`,
  );
export const updateAttendance = (attendanceId: any) => (attendanceStatus: any) =>
  apiClient.put(
    `/api/spaces/${window.spaceSlug}/attendances/${attendanceId}?attendance_status=${attendanceStatus}`,
  );

//
// Newsletters
//
export const getNewsletters = (params: any) => {
  const queryParams = qs.stringify(params, { arrayFormat: 'brackets' });
  return apiClient.get(`/api/spaces/${window.spaceSlug}/dashboard/newsletters?${queryParams}`);
};

export const getNewsletterUsersFilterCount = (data: any) => {
  const query = qs.stringify(data, { arrayFormat: 'brackets' });
  return apiClient
    .get(`/api/spaces/${window.spaceSlug}/dashboard/newsletters/users_filter_count?${query}`)
    .then((res: any) => res.data);
};

export const sendTestNewsletter = (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/dashboard/newsletters/test`, { data });
export const getNewsletter = (newsletterId: any) =>
  apiClient.get(`/api/spaces/${window.spaceSlug}/dashboard/newsletters/${newsletterId}`);
export const createNewsletter = (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/dashboard/newsletters`, { data });
export const updateNewsletter = (newsletterId: any, data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/dashboard/newsletters/${newsletterId}`, { data });

//
// Users
//

export const createExportUsers = (params: any) => {
  const query = qs.stringify(params, { arrayFormat: 'brackets' });
  return apiClient
    .post(`/api/spaces/${window.spaceSlug}/export/users?${query}`)
    .then((res: any) => res.data);
};

export const getUsers = ({ page, limit = 10, filter, search, sort }: any) => {
  const params = qs.stringify(
    {
      page: {
        limit,
        offset: page,
      },
      sort,
      filter: {
        q: search,
        ...filter,
      },
    },
    {
      arrayFormat: 'brackets',
      encode: false,
    },
  );

  return apiClient.get(`/api/spaces/${window.spaceSlug}/users?${params}`);
};

//
// Invitations
//
export const createInvitations = (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/dashboard/invitations/many`, data);

export const getInvitations = ({ page, limit = 10, search }: any) => {
  const params = qs.stringify(
    {
      page: {
        limit,
        offset: page,
      },
      q: search,
    },
    {
      arrayFormat: 'brackets',
      encode: false,
    },
  );

  return apiClient.get(`/api/spaces/${window.spaceSlug}/dashboard/invitations?${params}`);
};

export const deleteInvitation = (id: any) =>
  apiClient.delete(`/api/spaces/${window.spaceSlug}/dashboard/invitations/${id}`);

//
// NpsForms
//

export const createNpsForm = (data: any) =>
  apiClient.post(`/api/spaces/${window.spaceSlug}/nps_forms`, data);

//
// UiSections
//

export const getUiSections = (page: string) => {
  const params = qs.stringify(
    {
      filter: {
        page,
      },
    },
    {
      arrayFormat: 'brackets',
      encode: false,
    },
  );

  return apiClient.get(`/api/spaces/${window.spaceSlug}/ui_sections?${params}`);
};
export const updateUiSection = (id: number) => (data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/dashboard/ui_sections/${id}`, { data });

//
// MenuItems
//
export const updateMenuItems = (data: any) =>
  apiClient.put(`/api/spaces/${window.spaceSlug}/dashboard/menu_items`, { data });

export default {
  sign,
  signIn,
  resetPassword,
  updatePassword,
  logout,

  getProfile,
  updateProfile,

  updateMyPassword,
  createPassword,

  getProfCategories,

  getPost,
  getPosts,
  getMessages,
  pinUnpinPost,
  publishUnpublishPost,
  reportPostAsAbuse,
  reportCommentAsAbuse,
  updatePostStatus,
  getPostStatus,
  createPost,
  updatePost,
  updatePostDashboard,
  getCommentTranslation,

  getCommentsForPost,
  postComment,
  deleteComment,
  deletePrivateComment,
  reportComment,

  getTags,

  getSpace,
  getSpaceByCustomDomain,
  getMyEntities,
  getSpaces,

  votePost,

  deletePost,

  getReportReasons,
  postReportForPost,
  updateProofOfIdentity,
  getProofOfIdentity,
};
